.benefits-section {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: flex-start; 
  height: auto;
  background-color: whitesmoke;
  position: relative; 
  padding-top: 20px;
}

.ufo-content {
  width: 100%;
  height: auto;
  
}

.benefits-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: forestgreen; 
  opacity: 0;
  transform: translateY(-40px); 
  transition: opacity 1s ease-out, transform 1s cubic-bezier(0.25, 1.1, 0.5, 1.2); 
}

.benefits-title.visible {
  opacity: 1;
  transform: translateY(0); 
}



.benefits-list {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-around; 
  gap: 15px; 
  margin-top: 20px;
  list-style: none;
  padding: 40px;
}

.benefit-item {
  padding: 20px;
  width: 250px; 
  box-sizing: border-box;
  text-align: center;
  background: #ffffff; 
  border-radius: 10px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.benefit-item:hover {
  transform: translateY(-5px); 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}

.benefit-icon {
  width: 100px; 
  height: auto;
  margin-bottom: 10px;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out, box-shadow 0.6s ease-in-out;
}

.benefit-item:nth-child(1) .benefit-icon {
  transition-delay: 0.2s;
}

.benefit-item:nth-child(2) .benefit-icon {
  transition-delay: 0.4s;
}

.benefit-item:nth-child(3) .benefit-icon {
  transition-delay: 0.6s;
}

.benefit-item:nth-child(4) .benefit-icon {
  transition-delay: 0.8s;
}

.benefit-item:nth-child(5) .benefit-icon {
  transition-delay: 1s;
}

.benefit-icon.visible {
  opacity: 1;
  transform: scale(1);
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.5)); 
}

.benefit-item-text {
  display: block;
  font-size: clamp(1rem, 2vw, 1.2rem); 
  color: rgb(217, 156, 1);
  margin-top: 10px;
}

@media (max-width: 768px) {
  .benefit-item {
    width: 200px; 
  }
}

@media (max-width: 480px) {
  .benefit-item {
    width: 150px;
  }
}
