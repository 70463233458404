
.contact-section {
    position: relative;
    padding: 3em 1em;
    color: white;
    text-align: center;
    overflow: hidden;
  }
  
  .contact-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: local('/assets/images/fondo5.jpg') no-repeat center center;
    background-size: cover;
    z-index: 1;
  }
  
  .contact-section::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(39, 174, 96, 0.6); 
    z-index: 2;
  }
  
  .contact-section .container {
    position: relative;
    max-width: 600px;
    margin: 0 auto;
    padding: 2em;
    background: rgba(255, 255, 255, 0.9); 
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
    z-index: 3;
    opacity: 0; 
    transition: opacity 1.5s ease-out;
  }
  
  .contact-section .container.visible {
    opacity: 1; 
  }
  
  .contact-section h2 {
    margin-bottom: 1em;
    font-size: 2.5em; 
    color: #333; 
    font-weight: bold;
  }
  
  .contact-section form {
    display: flex;
    flex-direction: column;
    gap: 1.5em; 
  }
  
  .contact-section input,
  .contact-section textarea {
    padding: 1em; 
    border: 1px solid #ddd; 
    border-radius: 8px; 
    font-size: 1em;
    width: 100%;
    box-sizing: border-box; 
    transition: border-color 0.3s ease, box-shadow 0.3s ease; 
  }
  
  .contact-section input:focus,
  .contact-section textarea:focus {
    border-color: #27ae60; 
    box-shadow: 0 0 8px rgba(39, 174, 96, 0.5); 
    outline: none; 
  }
  
  .contact-section input[type="text"],
  .contact-section input[type="email"],
  .contact-section input[type="tel"] {
    background: #fff;
    color: #333;
  }
  
  .contact-section textarea {
    background: #fff;
    color: #333;
    height: 150px; 
    resize: vertical;
  }
  
  .contact-section button {
    background-color: #27ae60; 
    color: white;
    border: none;
    padding: 1em 2em; 
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease, transform 0.3s ease; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .contact-section button:hover {
    background-color: #2ecc71; 
    transform: translateY(-2px); 
  }
  