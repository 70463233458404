
.modules-section {
  padding: 10px 20px;
  background-color: #fdf7e0;
  text-align: center;
}

.modules-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.modules-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: forestgreen;
  opacity: 0; 
  transform: translateY(20px); 
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.modules-section .subtitle {
  font-size: 1.2rem;
  margin-bottom: 40px;
  opacity: 0; 
  transform: translateY(20px); 
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  transition-delay: 0.2s; 
}

.carousel-container {
  padding: 10px 10px 50px;
  position: relative;
  opacity: 0; 
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  transition-delay: 0.8s; 
}

.modules-section h2.visible {
  opacity: 1;
  transform: translateY(0);
}

.modules-section .subtitle.visible {
  opacity: 1;
  transform: translateY(0);
}

.carousel-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.module-carousel {
  padding: 0 20px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
}

.swiper-slide .module-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
}

.swiper-slide .module-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
  transform: translateY(-8px); 
  cursor: pointer;
}

.swiper-button-next,
.swiper-button-prev {
  color: rgb(217, 156, 1);
}

.swiper-pagination-bullet {
  background: rgb(217, 156, 1);
}
