/* Animaciones */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.header {
  background-color: white;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: fixed;
  height: 100px;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
}

/* Animación de flotación */
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.logo {
  width: 100%;
  max-width: 180px;
  height: auto;
  opacity: 0; 
  animation: slideInFromLeft 1s forwards;
}

.header .navbar .floating-icon-container {
  position: relative;
  cursor: pointer;
  animation: floatAnimation 2s infinite ease-in-out;
}

.header .navbar .floating-icon {
  width: 40px;
  height: auto;
}

.header .navbar .floating-icon-container:hover .messageBox {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

.messageBox {
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  background-color: green;
  color: white;
  padding: 6px 12px;
  border-radius: 8px;
  white-space: nowrap;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  box-shadow: 0px 0px 5px cyan;
}


.navbar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 30px;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-center {
  display: flex;
  flex: 1;
  justify-content: center;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 25px;
  margin: 0;
  padding: 0;
}

.navbar-links li a {
  text-decoration: none;
  padding: 10px;
  color: black;
  font-size: clamp(10px, 1.5vw, 20px); 
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-links li a:hover {
  background-color: #8fcf85;
  color: white;
}

.navbar-links li a.selected {
  color: forestgreen;
  font-weight: bold;
}

.navbar-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  color: black !important;
}

.btn-primary {
  background-color: forestgreen;
  color: white !important;
}

.btn-primary:hover {
  background-color: #8fcf85;
}

.btn-secondary {
  border: 1px solid forestgreen;
  color: forestgreen !important;
  text-align: center;
}

.btn-secondary:hover {
  background-color: #b1c0b2;
}

.navbar-toggle {
  display: none; 
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
  position: absolute; 
  top: 50%; 
  right: 40px; 
  transform: translateY(-50%); 
  z-index: 1100;
}


.navbar-links li {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.navbar-links li:nth-child(1) { animation-delay: 0.1s; }
.navbar-links li:nth-child(2) { animation-delay: 0.2s; }
.navbar-links li:nth-child(3) { animation-delay: 0.3s; }
.navbar-links li:nth-child(4) { animation-delay: 0.4s; }
.navbar-links li:nth-child(5) { animation-delay: 0.5s; }

.navbar-buttons a {
  opacity: 0;
  animation: fadeIn 0.2s forwards;
}

.navbar-buttons a:nth-child(1) {
  animation-delay: 0.8s;
}
.navbar-buttons a:nth-child(2) {
  animation-delay: 1s;
}


.line {
  width: 30px;
  height: 3px;
  background-color: black;
  transition: 0.4s;
}

.line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.line2 {
  opacity: 0;
}

.line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Estados Activos */
.navbar-center.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.navbar-center.active .navbar-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 80px;
  left: 0;
  padding: 12px 0;
}

.navbar-center.active .navbar-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex:1;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: calc(60px + 200px);
  left: 0;
  right:0;
  padding: 20px 0;
}

/* Media Queries */
@media (max-width: 1325px) {
  .logo {
    width: 100px;
    margin-left: -20px;
  }

  .navbar-links {
    display: none;
  }

  .navbar-center {
    display: none;
  }

  .navbar-buttons {
    display: none;
  }

  .navbar-toggle {
    display: flex;
  }

  .navbar-center.active {
    display: flex;
    justify-content: center; 
    margin-right: 20px !important;
  }

  .navbar-links a {
    font-size: 18px !important;
    margin-right: 25px;
  }

  .btn {
    font-size: 16px;
    margin-left: 30px !important;
  }
}
