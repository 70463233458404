.module-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 330px;
}

.module-card-img {
  background-color: rgb(141, 186, 141);
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.module-card-content {
  padding: 15px;
  flex-grow: 1;
}

.module-card-title {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.module-card-description {
  font-size: 1rem;
  color: #555;
}
