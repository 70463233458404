.hero-section {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: left;
  padding: 0 20px;
  overflow: hidden;
}

.hero-video,
.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill; 
  z-index: 0;
  cursor: pointer;
  transition: opacity 2s ease, transform 2s ease;
}

.hero-content {
  max-width: 600px;
  position: relative;
  z-index: 1;
  transition: opacity 1s ease, transform 1s ease;
  visibility: hidden;
  opacity: 0;
  transform: translateX(100%); 
}

.hero-content.visible {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.hero-content.hidden {
  opacity: 0;
  transform: translateY(-50px);
}

.play-video-btn {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  z-index: 2;
  transition: background-color 0.3s ease;
}

.play-video-btn:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.hero-content h1 {
  animation-delay: 0.1s;
}

.hero-content h2 {
  animation-delay: 0.6s;
}

.hero-content p {
  animation-delay: 0.8s;
  line-height: 1.5;
}

.hero-buttons-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.instagram-btn {
  animation-delay: 1s;
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #abc247;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.instagram-btn:hover {
  background-color: #95a83f;
  transform: scale(1.05);
}

.facebook-btn {
  animation-delay: 1s;
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #009688;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.facebook-btn:hover {
  background-color: #00796b;
  transform: scale(1.05);
}

.hero-icon {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}

.hero-content h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.hero-content h2 {
  font-size: 2.5rem;
  color: rgb(74, 250, 74);
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 20px 0;
}

.hero-content p {
  font-size: 1.2em;
  margin-bottom: 30px;
  align-items: center;
}

.dots-container {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  padding: 10px; /* Añadir espacio alrededor de los puntos */
  background: rgba(0, 0, 0, 0.5); /* Fondo oscuro y transparente */
  border-radius: 20px; /* Bordes redondeados */
}


.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.dot.active {
  background-color: green;
}

@media (max-width: 768px) {
  .hero-content {
    margin: auto;
    justify-content: center;
  }

  .hero-content h1 {
    font-size: 2em;
  }

  .hero-content h2 {
    font-size: 1.5rem;
  }

  .hero-content p {
    font-size: 1em;
  }

  .play-video-btn,
  .instagram-btn,
  .facebook-btn {
    font-size: 0.8em;
    padding: 8px 16px;
  }
}
