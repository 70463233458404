.download-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: radial-gradient(circle, #4caf50, #2e7d32);
  padding: 3em 1em;
  color: white;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.download-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.download-description {
  font-size: 1.2rem;
  margin-bottom: 2em;
}

.download-buttons {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.download-btn:hover {
  background: rgba(0, 0, 0, 0.6);
  transform: scale(1.05);
}

.download-icon {
  width: 40px;
  height: auto;
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.phone-image {
  position: absolute;
  width: 300px;
  filter: drop-shadow(0 10px 15px rgba(0, 0, 0, 0.4)), brightness(1.1); 
  z-index: 0;
  animation: float 10s ease-in-out infinite, glow 1.5s infinite alternate;
}

.phone-image-left {
  top: 20%;
  left: 0%;
  animation: float-left 6s ease-in-out infinite, glow 1.5s infinite alternate;
}

.phone-image-right {
  top: 20%;
  right: 0%;
  animation: float-right 6s ease-in-out infinite, glow 1.5s infinite alternate;
}

@keyframes float-left {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(-5deg);
  }
}

@keyframes float-right {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(5deg);
  }
}

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.6)); 
  }
  100% {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.8)); 
  }
}

@keyframes icon-bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.download-btn:hover .download-icon {
  animation: icon-bounce 1s infinite ease-in-out;
}


@media (max-width: 768px) {
  .phone-image {
    display: none; /* Oculta la imagen del teléfono */
  }
}