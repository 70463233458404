.explore-properties-section {
  position: relative;
  background-color: #fdf7e0;
  overflow: hidden;
}

#explore .container h2 {
  font-size: 2.5rem;
  color: #2e7d32; 
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5em;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1px;
}

#explore .container .title-paragraph {
  font-size: 1.3rem;
  margin-bottom: 1.5em;
  color: #333;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 1.5em auto;
}

.container {
  padding: 40px;
  margin: auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-top: 20px;
}

.content-item {
  display: flex;
  align-items: center;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease, transform 0.6s ease;
  padding-block: 40px;
  gap: 30px; 
}

.content-item.visible {
  opacity: 1;
  transform: translateY(0);
}

.image {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  position: relative; /* Añadir para efectos adicionales */
}

.image img,
.explore-video {
  width: 85%;
  border-radius: 12px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}


.image img:hover {
  transform: scale(1.05);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2); 
}

.explore-video:hover {
  transform: scale(1.05);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
}

.text {
  flex: 1;
  padding: 20px;
}

.content-item:nth-child(odd) .text {
  border-left: 4px solid #2e7d32; 
  padding-left: 30px; 
  padding-right: 0; 
}

.content-item:nth-child(even) {
  flex-direction: row-reverse;
}

.content-item:nth-child(even) .text {
  border-right: 4px solid #2e7d32; 
  padding-right: 30px; 
  padding-left: 0; 
}

.text h3 {
  font-size: 2.2rem;
  margin-bottom: 0.5em;
  color: black; 
  font-weight: 600;
  letter-spacing: 0.5px;
}

.text p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: rgba(255, 64, 0, 0.945);
  margin-bottom: 20px;
}

.explore-button {
  margin-top: 20px;
  padding: 16px 36px; 
  background-color: #2e7d32; 
  color: white;
  border: none;
  border-radius: 12px; 
  font-size: 1.3rem; 
  font-weight: 700; 
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
  transition: background-color 0.4s ease, transform 0.3s ease;
}

.explore-button:hover {
  background-color: #1b5e20; 
  transform: translateY(-3px); 
}
